export const environment = {
  firebase: {
    apiKey: 'AIzaSyBD9OYNQ77odxSIMOjONt2m2SK4SZZUB6U',
    authDomain: 'transzlacios-pedagogia-prod.firebaseapp.com',
    projectId: 'transzlacios-pedagogia-prod',
    storageBucket: 'transzlacios-pedagogia-prod.appspot.com',
    messagingSenderId: '910036737972',
    appId: '1:910036737972:web:a73ba2270d791da0c09ce1',
    measurementId: 'G-XZ570ZRFSE',
    databaseURL: 'https://transzlacios-pedagogia-prod-default-rtdb.europe-west1.firebasedatabase.app',
  },
  cfBase: 'https://europe-west1-transzlacios-pedagogia-prod.cloudfunctions.net/',
  production: true,
  version: '0.2.27',
  buildNumber: '',
  id: 'prod',
  region: 'europe-west1',
  mobileHostingURL: 'aeter.app',
};
